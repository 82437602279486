<template>
  <OneColumnTemplate>
    <template v-slot:ColumnContent>
      <div class="PageContentWrapper">
        <!-- <Navbar :hideNavbarLeftLogo="true" /> -->

        <span class="OctaS_PageTitleDefault">Инвентарь</span>

        <div class="Products-Block" :key="'product-block' + products_hash">
          <div
            v-for="(p, pindx) in store.products"
            :key="pindx + 'product-' + p.id"
            :class="{
              'Product-Card': true,
              noselect: true,
              'No-BG-IMG': p?.OrderProducts?.ProductImages?.length != 0,
            }"
            @click="
              () => {
                openModal(p);
              }
            "
          >
            <span class="Product-Time"
              >Приобретено {{ getNormalDate(p?.createdAt) }}</span
            >
            <span class="Product-Title">{{ p?.OrderProducts?.name }}</span>
            <span class="Product-Desc" v-if="p?.OrderProducts?.desc != null">{{
              p?.OrderProducts?.desc
            }}</span>
            <span
              class="Product-More-Button"
              v-if="p?.OrderProducts?.desc != null"
              @click="
                () => {
                  openModal(p);
                }
              "
              >подробнее</span
            >

            <div class="Product-Bottom-Block">
              <div class="Product-Tips">
                <span
                  v-for="(tip, tindx) in getTipsArray(p)"
                  :key="tindx + 'tip' + tip.text"
                  :class="tip.classessList"
                  >{{ tip.text }}</span
                >
              </div>

              <Octocoins class="OctaCoinsStyle" :BorderColor="'transparent'">
                <template v-slot:content>
                  <span class="Product-Price-Text">{{ p.total_price }}</span>
                </template>
              </Octocoins>
            </div>

            <div
              v-if="p?.OrderProducts?.ProductImages.length != 0"
              class="Product-Background-Image"
              :style="
                `
              background: url('${p?.OrderProducts?.ProductImages?.[0]?.url_file}');
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;`
              "
            ></div>
          </div>
        </div>

        <span
          v-if="store.loaded && store.products?.length === 0"
          :key="'TextNoItems-' + products_hash + store.products?.length === 0"
          class="NoItemsShowed"
          >На данный момент список пуст</span
        >

        <NewStyledModal
          class="Styled-Modal"
          :showModal="show_modal"
          :hideModal="
            () => {
              show_modal = false;
              product_selected = null;
            }
          "
          :key="show_modal"
        >
          <template v-slot:ModalContent>
            <div class="Modal-Card-Wrapper" v-if="product_selected != null">
              <div
                v-if="product_selected.OrderProducts?.ProductImages.length != 0"
                class="Modal-Card-Image"
                :style="
                  `
              background: url('${product_selected.OrderProducts?.ProductImages?.[0]?.url_file}');
              background-position: center;
              background-size: cover;
              background-repeat: no-repeat;
              filter: none;
              opacity: 1;
              `
                "
              ></div>

              <div class="Product-Card Modal-Card noselect">
                <span class="Product-Title">{{
                  product_selected.OrderProducts?.name
                }}</span>

                <span
                  class="Product-Desc"
                  v-if="product_selected.OrderProducts?.desc != null"
                  >{{ product_selected.OrderProducts?.desc }}
                </span>

                <div
                  class="Product-Bottom-Block"
                  :style="
                    product_selected.OrderProducts?.ProductImages.length != 0
                      ? 'margin-top: auto;'
                      : ''
                  "
                >
                  <div class="Product-Tips">
                    <span
                      v-for="(tip, tindx) in getTipsArray(product_selected)"
                      :key="tindx + 'tip' + tip.text"
                      :class="tip.classessList"
                      >{{ tip.text }}</span
                    >
                  </div>
                  <Octocoins
                    class="OctaCoinsStyle"
                    :BorderColor="'transparent'"
                  >
                    <template v-slot:content>
                      <span class="Product-Price-Text">{{
                        product_selected.total_price
                      }}</span>
                    </template>
                  </Octocoins>

                  <div class="Offer-Modal-Buttons noselect">
                    <span class="Product-Time" style="color: #D3CAFF4D;"
                      >приобретено
                      {{ getNormalDate(product_selected?.createdAt) }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
        </NewStyledModal>
      </div>
    </template>
  </OneColumnTemplate>
</template>

<script>
import { mapGetters } from "vuex";

import { getUserStoreOrders } from "@/api/store.js";

import Navbar from "@/components/Navbar/NavbarCombined.vue";
import OneColumnTemplate from "@/components/ProfileTemplates/OneColumn.vue";
import SortingWithFiltersPreset from "@/components/CustomElements/SortingWithFiltersPreset.vue";
import Octocoins from "@/components/CustomElements/Octocoins.vue";
import NewStyledModal from "@/components/CustomElements/NewStyledModal.vue";

export default {
  name: "OctaStore",
  components: {
    Navbar,
    OneColumnTemplate,
    SortingWithFiltersPreset,
    Octocoins,
    NewStyledModal,
  },
  data() {
    return {
      show_modal: false,

      filters: {
        sortingOptions: [
          {
            text: "Сортировка по умолчанию",
            val: "default",
          },
          {
            text: "Цена - Выше",
            val: "price_higher",
          },
          {
            text: "Цена - меньше",
            val: "price_lower",
          },
        ],
        searchInProperties: ["name", "desc", "price"],
        filtersButtons: [
          {
            text: "Доступные",
            val: "available",
          },
          {
            text: "Новинки",
            val: "new",
          },
          // {
          //   text: "Рекомендуемые",
          //   val: "recommended",
          // },
          {
            text: "Недоступные",
            val: "unavailable",
          },
        ],
        selectedButton: null,
        selectedSorting: "default",
        selectedSearch: "",
      },

      store: {
        loaded: false,

        user_coins: 0,
        load_per_request: 20,
        offset: 0,

        products: [],
        products_filtered: [],
        products_counter: 0,

        load_more_is_active: false,
      },

      product_selected: null,
    };
  },
  async created() {
    await this.initialization();
  },
  methods: {
    async initialization() {
      let _store_products = await getUserStoreOrders(this?.user?.id);
      this.store.products = _store_products.data.reverse();

      if (!this.store.loaded) {
        this.store.loaded = true;
      }
    },

    getTipsArray(product) {
      let array = [];
      if (product.status === "Заказ выполнен") {
        array.push({
          text: product.status,
          classessList: "Product-Tip Is-New",
        });
      }

      if (product.status === "Ожидает обработки") {
        array.push({
          text: product.status,
          classessList: "Product-Tip Few-Products",
        });
      }

      if (product.status === "Доставляется") {
        array.push({
          text: product.status,
          classessList: "Product-Tip Is-Recommended",
        });
      }

      return array;
    },
    isNewProduct(product_created) {
      const specifiedDate = new Date(product_created);
      const currentDate = new Date();

      const difference = currentDate.getTime() - specifiedDate.getTime();

      return difference < 5 * 24 * 60 * 60 * 1000;
    },

    openModal(product) {
      this.product_selected = product;
      this.show_modal = true;
    },

    sortingMethods(sort_name, array) {
      if (sort_name === "price_higher") {
        array = array.sort((a, b) => {
          return b.price - a.price;
        });

        return array;
      }

      if (sort_name === "price_lower") {
        array = array.sort((a, b) => {
          return a.price - b.price;
        });

        return array;
      }

      return array;
    },
    handleSearchFilterSortingObject(Array) {
      this.store.products_filtered = Array;
    },
    handleFilterButton(val) {
      this.filters.selectedButton = val;
      this.store.offset = 0;
      this.initialization();
    },
    handleFilterChanges(obj) {
      this.filters.selectedButton = obj.button;
      this.filters.selectedSearch = obj.search;
      this.filters.selectedSorting = obj.sorting;
    },

    getNormalDate(BDDate) {
      let date = BDDate.split("T")[0].split("-");
      return `${date[2]}.${date[1]}.${date[0]}`;
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),

    products_hash() {
      let a = this.store.products.map((x) => `${x.id}`);
      return `${this.store.products?.length}-${a.join("-")}`;
    },

    products_filtered_hash() {
      let a = this.store.products_filtered.map((x) => `${x.id}`);
      return `${this.store.products_filtered?.length}-${a.join("-")}`;
    },
  },
};
</script>

<style scoped>
.Page-Title {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #ffffff;
}

.Product-Time {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;

  color: #ffffffe5;
}

.NoItemsShowed {
  position: relative;
  display: block;

  margin-top: 50px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 30px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;
}

.Offer-Button-Next.Load-More-Objects {
  width: fit-content;
  flex-shrink: 0;

  margin: 0% auto;
}

.PageContentWrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 36px;

  width: 100%;
  height: 100%;

  padding-right: 15px;

  overflow: auto;
}

.Products-Block {
  position: relative;
  display: grid;

  grid-template-columns: repeat(5, calc((100% - 24px * 4) / 5));
  gap: 24px;

  width: 100%;
  height: fit-content;
}

.Product-Card {
  position: relative;
  display: flex;
  cursor: pointer;

  padding: 20px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: auto;
  /* aspect-ratio: 0.88 / 1; */

  border-radius: var(--o-s-global-border-radius);

  background: url("./../../assets/img/Admin/LogoBG.png");
  background-position: 70% 0%;
  background-size: 60%;
  background-repeat: no-repeat;
  background-color: #1e1b4a;

  transition-timing-function: ease-in;
  transition: 0.2s;

  overflow: hidden;
}
.Product-Card:hover {
  background-color: #27235c;
}
.Product-Card.No-BG-IMG {
  background: #1e1b4a;
  background-color: #1e1b4a;
}
.Product-Card > *:not(.Product-Background-Image) {
  z-index: 5;
}
.Product-Card:hover > .Product-Background-Image {
  opacity: 0.05;
}
.Product-Background-Image {
  position: absolute;
  display: block;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 12px;

  filter: blur(2px);
  opacity: 0.1;
  z-index: 3;
}
.Modal-Card-Image {
  position: relative;
  display: block;

  top: 0;
  left: 0;

  max-width: 500px;
  width: auto;
  height: 300px;

  aspect-ratio: 0.88 / 1;

  border-radius: 8px;

  filter: blur(2px);
  opacity: 0.1;
  z-index: 3;
}

.Product-Title {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #ffffffe5;
}
.Product-Desc {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #ffffffe5;
}
.Product-More-Button {
  position: relative;
  display: block;

  cursor: pointer;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;

  color: #656bff;
}

.Product-Transfer-Button {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 8px 0px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;

  border-radius: 4px;

  color: #ffffffe5;
  background-color: #656bff;

  transition-timing-function: ease-in;
  transition: 0.2s;

  opacity: 1;
}
.Product-Transfer-Button:hover,
.Offer-Button-Next:hover {
  background-color: #656bffe5;
}
.Product-Transfer-Button.Not-Available,
.Offer-Button-Next.Not-Available {
  cursor: default;
  opacity: 0.3;
}

.Product-Bottom-Block {
  position: relative;
  display: flex;

  margin-top: auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
  height: fit-content;
}
.Product-Price-Text {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}
.OctaCoinsStyle {
  gap: 4px;
  padding: 0px;
}
.OctaCoinsStyle:deep(.coinsLogo) {
  width: auto;
  height: 20px;

  aspect-ratio: 0.4705 / 1;
}

.Product-Tips {
  position: relative;
  display: flex;

  margin-bottom: 4px;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 4px;

  width: 100%;
  height: fit-content;
}
.Product-Tip {
  position: relative;
  display: block;

  padding: 4px 8px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  border-radius: 4px;
}
.Product-Tip.Not-Enough-Coins {
  color: #ed254e;
  border: 1px solid #ed254e;
  background-color: transparent;
}
.Product-Tip.Is-New {
  color: #25ed69;
  border: 1px solid #25ed6926;
  background-color: #25ed6926;
}
.Product-Tip.Is-Recommended {
  color: #e924bf;
  border: 1px solid #e924bf26;
  background-color: #e924bf26;
}
.Product-Tip.Few-Products {
  color: #f9dc5c;
  border: 1px solid #f9dc5c26;
  background-color: #f9dc5c26;
}
</style>

<style scoped>
.Styled-Modal:deep(.SendRequestModal) {
  min-width: 0;
  width: fit-content;
  background: #1e1b4a;
  border-radius: var(--o-s-global-border-radius);
}

.Modal-Card-Wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;

  width: fit-content;
  height: fit-content;
}
.Product-Card.Modal-Card {
  position: relative;

  min-width: 0;
  width: 320px;
  height: auto;
  padding: 0px;

  border-radius: 0px;
  background: transparent;
  background-color: transparent;
}
.Product-Card.Modal-Card .Product-Bottom-Block {
  margin-top: 8px;
}
.Product-Card.Modal-Card .Product-Desc {
  display: block;
  color: #d3caffb2;
}

.Offer-Modal {
  position: relative;
  display: flex;

  padding-top: 12px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;

  width: fit-content;
  height: fit-content;
}

.Offer-Modal-Title {
  position: relative;
  display: block;

  min-width: 320px;
  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}
.Offer-Modal-Content {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  min-width: 320px;
  width: fit-content;
  height: fit-content;
}

.Input-Floating-Label {
  position: relative;
  display: block;

  min-width: 320px;
  width: fit-content;
  height: fit-content;
}

.Input-styled {
  position: relative;
  display: block;

  padding: 12px 8px;

  width: 100%;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  background-color: transparent;

  outline: 0;
  outline-offset: 0;

  border-radius: 4px;
  border: 1px solid #d3caff40;
}
.Input-styled::placeholder {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #d3caff73;
}
.Input-styled:focus {
  border-color: #656bff;
}
.Input-styled.Input-highligt-error {
  border-color: #ed254e;
}
.Input-styled.Input-highligt-error:focus + .Input-Placeholder,
.Input-styled.Input-highligt-error + .Input-Placeholder {
  color: #ed254e;
}

.Input-Placeholder {
  position: absolute;
  display: block;

  top: 0;
  left: 18px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;

  transform: translate(0px, -50%);

  transition-timing-function: ease-in;
  transition: 0.2s;

  color: #d3caff4d;
  background: #1e1b4a;
  padding: 0% 2px;
}
.Input-styled:focus + .Input-Placeholder {
  color: #656bff;
}

.Offer-Modal-Buttons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Button-Next {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: 100%;
  height: fit-content;

  flex-shrink: 1;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff;
}
.Offer-Button-Prev {
  position: relative;
  display: block;
  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  flex-shrink: 0;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #ffffffe5;

  transition-timing-function: ease-in;
  transition: 0.2s;

  border-radius: 4px;
  background-color: #656bff40;
}
.Offer-Button-Prev:hover {
  background-color: rgba(101, 106, 255, 0.144);
}

.Offer-Text-Before-Confirm {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: #d3caffb2;
}
.Offer-Text-Before-Confirm .Text-Before-Confirm-Important {
  color: #ffffffe5;
}

.Offer-Modal-Buttons-Child-Wrapper {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 320px;
  height: fit-content;
}

.Offer-Text-Balance-After {
  position: relative;
  display: block;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: uppercase;

  color: #d3caff4d;
}
.Offer-Text-Error {
  position: relative;
  display: block;

  margin-top: 12px;

  width: 320px;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  text-transform: none;
  color: #ed254e;
}
</style>

<style scoped>
@media (max-width: 480px) {
  .Products-Block {
    grid-template-columns: repeat(1, calc((100% - 24px * 0) / 1));
    padding-bottom: 100px;
  }

  .Modal-Card-Wrapper {
    padding-top: 20px;
    flex-direction: column;
  }

  .Modal-Card-Image {
    max-width: 100%;
    height: 150px;
  }

  .Styled-Modal:deep(.SendRequestModal) {
    margin: 20px auto auto auto;
    width: calc(100% - 40px);
  }

  .Product-Card.Modal-Card {
    width: 100%;
  }

  .Offer-Modal-Buttons {
    width: 100%;
  }

  .Offer-Button-Next,
  .Offer-Button-Prev {
    padding: 4px 8px;
    font-size: 13px;
  }

  .Offer-Modal-Title,
  .Offer-Modal-Content,
  .Input-Floating-Label,
  .Offer-Text-Before-Confirm,
  .Offer-Modal-Buttons-Child-Wrapper,
  .Offer-Text-Balance-After {
    width: 100%;
    min-width: 0;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .Products-Block {
    grid-template-columns: repeat(2, calc((100% - 24px * 1) / 2));
  }

  .Modal-Card-Wrapper {
    padding-top: 20px;
    flex-direction: column;
  }

  .Modal-Card-Image {
    max-width: 100%;
    height: 200px;
  }

  .Styled-Modal:deep(.SendRequestModal) {
    margin: 50px auto auto auto;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .Products-Block {
    grid-template-columns: repeat(3, calc((100% - 24px * 2) / 3));
  }
}
@media (min-width: 992px) and (max-width: 1440px) {
  .Products-Block {
    grid-template-columns: repeat(4, calc((100% - 24px * 3) / 4));
  }
}
</style>
