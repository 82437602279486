import request from "@/api/config.js";

export function getStoreProductsByFilters(data) {
  return request({
    url: `store/get-products/by-filters`,
    method: "post",
    data,
  });
}
export function getProductsOnSiteCounter(data) {
  return request({
    url: `store/get-products-counter/by-filters`,
    method: "post",
    data,
  });
}
export function adminGetProductsByFilters(data) {
  return request({
    url: `store/get-admin-products/by-filters`,
    method: "post",
    data,
  });
}

export function buyProduct(data) {
  return request({
    url: `store/buy-product`,
    method: "post",
    data,
  });
}

export function setArchieveState(data) {
  return request({
    url: `store/set-product-archieve-state`,
    method: "post",
    data,
  });
}

export function deleteProductMaterial(mat_id) {
  return request({
    url: `store/product/delete-material/${mat_id}`,
    method: "get",
  });
}
export function uploadProductMaterials(product_id, data) {
  return request({
    url: `store/product/${product_id}/upload-materials`,
    method: "post",
    data,
  });
}
export function updateProduct(product_id, data) {
  return request({
    url: `store/product/${product_id}/update`,
    method: "post",
    data,
  });
}

export function getUserStoreOrders(user_id) {
  return request({
    url: `store/get-orders/by-user/${user_id}`,
    method: "get",
  });
}

export function getAdminOrders(data) {
  return request({
    url: `store/product-orders/admin-manage`,
    method: "post",
    data,
  });
}

export function updateOrderStatus(data) {
  return request({
    url: `store/order-update/status`,
    method: "post",
    data,
  });
}

export function createProduct(data) {
  return request({
    url: `store/product-create/new`,
    method: "post",
    data,
  });
}
