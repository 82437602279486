<template>
  <div class="ModalWrapper" v-show="showModal">
    <div class="SendRequestModal">
      <div class="Close" @click="hideModal"></div>

      <slot name="ModalContent"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewStyledModal",
  components: {},
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    hideModal: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.ModalWrapper {
  position: fixed;

  top: 0;
  left: 0;

  display: flex;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.75);

  z-index: 500;
}

.SendRequestModal {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 14px;

  width: fit-content;
  min-width: 464px;
  height: fit-content;

  max-width: 100%;
  max-height: 98%;

  overflow-y: auto;

  padding: 20px;
  margin: auto;

  background: linear-gradient(
    224.99deg,
    #1e1b4a 0.05%,
    #1f1c4a 48.65%,
    #31337b 100.05%
  );
  border-radius: 8px;

  z-index: 10;

  scrollbar-width: thin;
  scrollbar-color: #413e7400 transparent;
}
.SendRequestModal::-webkit-scrollbar {
  width: 0px;
}
.SendRequestModal::-webkit-scrollbar-track {
  background: transparent;
}
.SendRequestModal::-webkit-scrollbar-thumb {
  background-color: #413e7400;
  border-radius: 0px;
  border: 0px solid transparent;
}

.Close {
  position: absolute;
  display: block;

  top: 0;
  right: 0;

  width: 32px;
  height: 32px;

  background: url("./../../assets/img/Landing/CloseButtonForRegModal.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;

  border-radius: 4px;

  background-color: #656bff40;

  z-index: 10;
}
</style>
